import { cnpj, cpf } from 'cpf-cnpj-validator'
// @ts-expect-error no types
import isEmail from 'is-email'
import type { Formats, TranslationValues } from 'next-intl'
import type { GroupBase } from 'react-select'
import type { LoadOptions } from 'react-select-async-paginate'
import * as ss from 'superstruct'

import { validarEmail, validSsnAndEin } from '../validators/etc'

export const CNPJ = ss.define<string>('CNPJ', (v) => cnpj.isValid(v as string))
export const CPF = ss.define<string>('CPF', (v) => cpf.isValid(v as string))
export const Email = ss.define<string>('Email', isEmail)
export const CredencialValidation = ss.define<string>(
  'Email',
  (v) =>
    validarEmail(v as string) ||
    cnpj.isValid(v as string) ||
    cpf.isValid(v as string) ||
    validSsnAndEin(v as string)
)
export const UUID = ss.string()
export const Pass = ss.size(ss.string(), 8, Infinity)

// --------------------------------------------------------------------------------------

export const DBRow = ss.object({
  id: UUID,
  createdAt: ss.string(),
  updatedAt: ss.string(),
  _count: ss.optional(ss.nullable(ss.any())),
})

export type DBRow = ss.Infer<typeof DBRow>

export const MongoDoc = ss.object({
  _id: ss.string(),
  __v: ss.optional(ss.nullable(ss.number())),
  createdAt: ss.optional(ss.nullable(ss.string())),
  updatedAt: ss.optional(ss.nullable(ss.string())),
})

// --------------------------------------------------------------------------------------

export const FileRecord = ss.assign(
  MongoDoc,
  ss.object({
    id: ss.optional(ss.nullable(ss.string())),
    name: ss.string(),
    url: ss.string(),
    mongoDbId: ss.nullable(ss.optional(ss.string())),
    size: ss.number(),
    key: ss.string(),
    type: ss.string(),
    enviadoPorId: ss.nullable(ss.optional(ss.string())),
    enviadoPorPerfil: ss.nullable(ss.optional(ss.string())),
    criadoPorPerfil: ss.nullable(ss.optional(ss.string())),
    criadoPorId: ss.nullable(ss.optional(ss.string())),
  })
)
export type FileRecord = ss.Infer<typeof FileRecord>

// --------------------------------------------------------------------------------------

export interface Media {
  thumbUrl: string
  file: File
}

export interface Option<T> {
  label: string
  value: T
  translate?: string
}

export type AsyncLoadOptions<T, R = Record<string, unknown>> = LoadOptions<
  Option<T>,
  GroupBase<Option<T>>,
  { page: number } & R
>

export type Translate = <TargetKey>(
  key: TargetKey,
  values?: TranslationValues | undefined,
  formats?: Partial<Formats> | undefined
) => string

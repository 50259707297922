import { useQuery } from '@tanstack/react-query'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { useTranslations } from 'next-intl'
import { usePostHog } from 'posthog-js/react'
import React from 'react'

import { useIsImpersonating } from '@/hooks/core/useIsImpersonating'
import { useAuth } from '@/hooks/use-auth'
import { parseSessionFromAuthMe } from '@/utils/auth'
import { nomeUsuario } from '@/utils/core/etc'
import { shortenName } from '@/utils/etc'
import * as fetch from '@/utils/fetch'
import type { MeResponseDto } from '@/utils/types/api/portal/dto'
import type {
  Credencial,
  TipoCredencial,
  User,
} from '@/utils/types/structs/auth'

const AprovacaoPendente = dynamic(
  () => import('@/components/core/AprovacaoPendente')
)

interface Session {
  role: TipoCredencial
  credential: Credencial
  profile: User
}

interface SessionContextValue {
  status: 'loading' | 'authenticated' | 'unauthenticated'
  data: Session | null
  update: () => Promise<{ profile: User; credential: Credencial } | null>
}

const SessionContext = React.createContext<SessionContextValue>({
  status: 'loading',
  data: null,
  update: async () => null,
})

export const useSession = () => {
  const c = React.useContext(SessionContext)
  if (!c) {
    throw new Error(
      'useSession hook can only be called inside a <SessionProvider /> component'
    )
  }
  return c
}

export const SessionProvider: React.FC<{
  children: React.ReactNode
  session?: Session
}> = ({ children, session }) => {
  const { pathname } = useRouter()
  const posthog = usePostHog()

  if (!SessionContext) {
    throw new Error('React Context is unavailable in Server Components')
  }

  const ONE_HOUR_MILLIS = 1000 * 60 * 60

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['me'],
    queryFn: ({ signal }) =>
      fetch
        .portal<MeResponseDto>('/auth/me', { signal })
        .then(({ data }) => data)
        .catch(() => null),
    staleTime: ONE_HOUR_MILLIS,
    retry: false,
  })

  const update = React.useCallback(() => {
    return refetch().then(({ data }) => {
      if (data) {
        const { credential, profile } = parseSessionFromAuthMe(data)
        return credential && profile ? { credential, profile } : null
      } else {
        return null
      }
    })
  }, [refetch])

  const value = React.useMemo<SessionContextValue>(() => {
    if (session && isLoading) {
      return {
        status: 'authenticated',
        data: session,
        update,
      }
    }

    let profile
    let credential

    if (data) {
      const parsedMeResponse = parseSessionFromAuthMe(data)
      profile = parsedMeResponse.profile
      credential = parsedMeResponse.credential
    }

    return {
      status: isLoading
        ? 'loading'
        : profile && credential
        ? 'authenticated'
        : 'unauthenticated',
      data:
        profile && credential
          ? { profile, role: credential.roles?.[0], credential }
          : null,
      update,
    }
  }, [session, isLoading, data, update])

  React.useEffect(() => {
    if (value.status === 'authenticated' && value.data) {
      const { profile, credential } = value.data
      posthog.identify(profile.id, {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        email: credential.email!,
        roles: credential.roles,
        ...(nomeUsuario(profile) && { name: nomeUsuario(profile) }),
      })
    } else {
      posthog.reset()
    }
  }, [value, posthog])

  return (
    <SessionContext.Provider value={value}>
      <ImpersonatorNotice />

      {value.data &&
        (value.data.profile as any).status &&
        ['LEAD', 'EM_ANALISE'].includes((value.data.profile as any).status) && (
          <AprovacaoPendente
            nome={shortenName(nomeUsuario(value.data.profile))}
          />
        )}

      {/^\/\[locale\]\/(area-.*|projects)/.test(pathname)
        ? isLoading
          ? null
          : children
        : children}
    </SessionContext.Provider>
  )
}

const ImpersonatorNotice = () => {
  const { isImpersonating, impersonator } = useIsImpersonating()
  const t = useTranslations('common.impersonator-notice')
  const { logout } = useAuth()

  if (!isImpersonating) return null

  return (
    <div className="py-2 px-6 bg-warning-300 text-center">
      <h2 className="text-body-sm inline">{t('impersonator')}.</h2>{' '}
      <button
        data-test-id="impersonate-reset"
        type="button"
        className="underline font-semibold text-body-sm inline"
        onClick={() => logout(impersonator?.redirectTo)}
      >
        {t('come-back')}
      </button>
    </div>
  )
}

import { useMutation } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import { useTranslations } from 'next-intl'
import React from 'react'

import { MixpanelEvent } from '@/components/Mixpanel'
import { useSession } from '@/contexts/auth/SessionProvider'
import { setJwtToken } from '@/utils/auth'
import { LOGIN_REDIRECT_ROUTE, UNAUTH_REDIRECT_COOKIE } from '@/utils/constants'
import { defaultToastError } from '@/utils/etc'
import * as fetch from '@/utils/fetch'

export function useAuth() {
  const t = useTranslations()
  const { push, query } = useRouter()
  const session = useSession()

  const login = useMutation({
    mutationFn: async ({
      credencial,
      senha,
    }: {
      credencial: string
      senha: string
    }) => {
      const getRolesResult = await fetch
        .portal<{ roles: string[] }>('/auth/credencial/buscar-roles', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ credencial, senha }),
        })
        .then(({ data }) => data)

      if (!getRolesResult?.roles?.[0]) return null

      const role = getRolesResult.roles[0]

      const signInResult = await fetch
        .portal<{ token: string }>(
          `/auth/login/${role !== 'VENDEDOR' ? role : 'INTEGRADOR'}`,
          {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              login: { credencial, senha },
            }),
          }
        )
        .then(({ data }) => data?.token)

      if (!signInResult) return null

      const jwt = signInResult

      setJwtToken({ jwt })
      // This is used to logout if token has expired
      document.cookie = `${UNAUTH_REDIRECT_COOKIE}=true;max-age=0`
      const currentSession = await session.update()

      let queryCallbackUrl = query.callbackUrl?.toString()
      queryCallbackUrl =
        queryCallbackUrl && decodeURIComponent(queryCallbackUrl)

      // If trying to login to an invalid area, redirect to the defalt LOGIN_REDIRECT_ROUTE
      const area = queryCallbackUrl?.match(/^\/area-(.+?)\//)?.[1]
      if (
        area !== currentSession?.credential?.roles?.[0]?.toLowerCase() &&
        queryCallbackUrl?.startsWith('/area-')
      ) {
        queryCallbackUrl = undefined
      }

      const callbackUrl = queryCallbackUrl || LOGIN_REDIRECT_ROUTE

      // Full reload feels more responsive
      location.assign(callbackUrl)
    },
    onError: defaultToastError,
  })

  const logout = React.useCallback(
    (callbackUrl?: string) => {
      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i)
        if (key?.toLowerCase().startsWith('tablestate')) {
          localStorage.removeItem(key)
        }
      }
      MixpanelEvent.track(t('common.mixpanel-8'))
      setJwtToken({ jwt: null })
      session.update()
      push(
        `/login?callbackUrl=${encodeURIComponent(
          callbackUrl || location.pathname
        )}`
      )
    },
    [session, push, t]
  )

  return {
    user: {
      perfil: session?.data?.profile,
      credencial: session?.data?.credential,
    },
    context: session?.data?.role,
    login: login.mutateAsync,
    logout,
    update: session.update,
  }
}

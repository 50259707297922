import * as ss from 'superstruct'

import {
  statusUsuario,
  tipoContaBanco,
  tipoPessoa,
  tiposChavePix,
} from '@/utils/types'
import type { Option, Translate } from '@/utils/types/common'
import {
  CNPJ,
  CPF,
  CredencialValidation,
  DBRow,
  FileRecord,
  UUID,
} from '@/utils/types/common'
import { AcessoConcessionaria, Equipamento } from '@/utils/types/structs'

import { Funding } from './funding'

export const resultadosAnaliseCredito = [
  'APROVADO',
  'PENDENTE',
  'REPROVADO',
] as const

export type Perfil = {
  active?: TipoCredencial
  credencial?: Credencial
  perfil?: any
}

// --------------------------------------------------------------------------------------

export const ProviderCredencial = ss.enums(['EMAIL', 'GOOGLE', 'FACEBOOK'])
export type ProviderCredencial = ss.Infer<typeof ProviderCredencial>

// --------------------------------------------------------------------------------------

export const TipoCredencial = ss.enums([
  'INTEGRADOR',
  'DISTRIBUIDOR',
  'BACKOFFICE',
  'VENDEDOR',
  'CLIENTE',
  'FINANCIADOR',
])
export type TipoCredencial = ss.Infer<typeof TipoCredencial>

export const TipoProjeto = ss.enums(['SOLAR', 'HOMEIMPROVE'])
export type TipoProjeto = ss.Infer<typeof TipoCredencial>

export const MotivosCancelamento = ss.enums([
  'REPROVADA_P1',
  'CANCELADA_PELO_BANCO',
  'DADOS_DA_OPERACAO_DESCONFORMES',
  'DESISTENCIA_DO_CLIENTE',
  'FALTA_DE_EVIDENCIAS',
  'IN_LOCO_DESFAVORAVEL',
  'DOCUMENTOS_DE_CREDITO_INCORRETOS',
  'BYPASS',
  'OUTROS',
  'FORA_DA_POLITICA_DO_FINANCIADOR',
  'DOCUMENTOS_NAO_RECEPCIONADOS',
  'PROPOSTA_DUPLICADA',
  'CANCELADA_PELO_ORIGINADOR',
])
export type MotivosCancelamento = ss.Infer<typeof MotivosCancelamento>

// --------------------------------------------------------------------------------------

export const Credencial = ss.assign(
  DBRow,
  ss.object({
    provider: ProviderCredencial,
    email: ss.nullable(ss.optional(CredencialValidation)),
    hashSenha: ss.optional(ss.nullable(ss.string())),
    tokenTrocaEmail: ss.nullable(ss.string()),
    tokenRecuperacao: ss.nullable(ss.string()),
    roles: ss.array(TipoCredencial),
    inativa: ss.defaulted(ss.boolean(), false),
    distribuidorId: ss.optional(ss.nullable(UUID)),
    lastLoginAt: ss.optional(ss.nullable(ss.string())),
    accountDeleteRequestedAt: ss.nullable(ss.string()),
    publicKey: ss.nullable(ss.optional(ss.string())),
    publicKeyHash: ss.nullable(ss.optional(ss.string())),
    name: ss.nullable(ss.optional(ss.string())),
    lastname: ss.nullable(ss.optional(ss.string())),
    phone: ss.nullable(ss.optional(ss.string())),
    lastProjectFilterId: ss.nullable(ss.number()),
    defaultProjectFilterId: ss.nullable(ss.number()),
    department: ss.nullable(ss.string()),
  })
)
export type Credencial = ss.Infer<typeof Credencial>

// --------------------------------------------------------------------------------------

export const Endereco = ss.assign(
  DBRow,
  ss.object({
    cep: ss.optional(ss.nullable(ss.string())),
    logradouro: ss.optional(ss.nullable(ss.string())),
    numero: ss.optional(ss.nullable(ss.string())),
    complemento: ss.optional(ss.nullable(ss.string())),
    bairro: ss.optional(ss.nullable(ss.string())),
    cidade: ss.optional(ss.nullable(ss.string())),
    estado: ss.optional(ss.nullable(ss.string())),
    situacaoImovel: ss.optional(ss.nullable(ss.string())),
    metragemQuadradaM2: ss.optional(ss.nullable(ss.number())),
    googleAddressId: ss.optional(ss.nullable(ss.string())),
    googleAddressDescription: ss.optional(ss.nullable(ss.string())),
  })
)
export type Endereco = ss.Infer<typeof Endereco>

// --------------------------------------------------------------------------------------

export const Contato = ss.assign(
  DBRow,
  ss.object({
    nome: ss.optional(ss.nullable(ss.string())),
    email: ss.optional(ss.nullable(ss.string())),
    telefone: ss.optional(ss.nullable(ss.string())),
    celular: ss.optional(ss.nullable(ss.string())),
    whatsapp: ss.optional(ss.nullable(ss.string())),
    site: ss.optional(ss.nullable(ss.string())),
    facebook: ss.optional(ss.nullable(ss.string())),
    instagram: ss.optional(ss.nullable(ss.string())),
    linkedin: ss.optional(ss.nullable(ss.string())),
    youtube: ss.optional(ss.nullable(ss.string())),
    outro: ss.optional(ss.nullable(ss.string())),
  })
)
export type Contato = ss.Infer<typeof Contato>

// --------------------------------------------------------------------------------------

export const Empresa = ss.assign(
  DBRow,
  ss.object({
    cnpj: ss.optional(ss.nullable(ss.string())),
    nomeFantasia: ss.optional(ss.nullable(ss.string())),
    razaoSocial: ss.nullable(ss.optional(ss.string())),
    enderecoId: ss.optional(ss.nullable(UUID)),
    contatoId: ss.optional(ss.nullable(UUID)),
    numeroIntegradoresCadastrados: ss.optional(ss.nullable(ss.string())),
    endereco: ss.optional(ss.nullable(Endereco)),
    contato: ss.optional(ss.nullable(Contato)),
    rendaMensal: ss.optional(ss.nullable(ss.number())),
    status: ss.optional(ss.nullable(ss.enums(statusUsuario))),
    arquivoContratoSocialId: ss.optional(ss.nullable(ss.string())),
    arquivoContratoSocial: ss.optional(ss.nullable(FileRecord)),

    arquivoComprovanteEnderecoId: ss.optional(ss.nullable(ss.string())),
    arquivoComprovanteEndereco: ss.optional(ss.nullable(FileRecord)),

    arquivoFaturamentoId: ss.optional(ss.nullable(ss.string())),
    arquivoFaturamento: ss.optional(ss.nullable(FileRecord)),

    arquivoDREId: ss.optional(ss.nullable(ss.string())),
    arquivoDRE: ss.optional(ss.nullable(FileRecord)),

    arquivoBalancoId: ss.optional(ss.nullable(ss.string())),
    arquivoBalanco: ss.optional(ss.nullable(FileRecord)),

    arquivoComprovanteIdentidadeId: ss.optional(ss.nullable(ss.string())),
    arquivoComprovanteIdentidade: ss.optional(ss.nullable(FileRecord)),

    hubSpotId: ss.nullable(ss.optional(ss.string())),
    pipedriveId: ss.nullable(ss.optional(ss.string())),

    dunsNumber: ss.nullable(ss.optional(ss.string())),
    consultorRelacionamentoResponsavel: ss.nullable(ss.optional(ss.string())),
    licenseNumber: ss.nullable(ss.optional(ss.string())),
  })
)
export type Empresa = ss.Infer<typeof Empresa>

// --------------------------------------------------------------------------------------

export const IdentidadePF = ss.assign(
  DBRow,
  ss.object({
    nomeCompleto: ss.nullable(ss.string()),
    nomeEmpresa: ss.optional(ss.nullable(ss.string())),
    cpf: ss.nullable(ss.string()),
    rg: ss.nullable(ss.optional(ss.string())),
    contatoId: ss.nullable(ss.optional(UUID)),
    enderecoId: ss.nullable(ss.optional(UUID)),

    email: ss.nullable(ss.optional(ss.string())),
    dataNascimento: ss.nullable(ss.optional(ss.string())),
    rendaMensal: ss.nullable(ss.optional(ss.number())),
    rendaAnual: ss.nullable(ss.optional(ss.number())),
    outrasRendas: ss.nullable(ss.optional(ss.number())),
    outrasRendasDescricao: ss.nullable(ss.optional(ss.string())),
    nomeMae: ss.nullable(ss.optional(ss.string())),
    cargoFuncao: ss.nullable(ss.optional(ss.string())),
    sexo: ss.nullable(ss.optional(ss.string())),
    estadoCivil: ss.nullable(ss.optional(ss.string())),
    nacionalidade: ss.nullable(ss.optional(ss.string())),
    patrimonio: ss.nullable(ss.optional(ss.number())),
    situacaoImovel: ss.nullable(ss.optional(ss.string())),
    naturezaOcupacao: ss.nullable(ss.optional(ss.string())),
    tempoEmpresa: ss.nullable(ss.optional(ss.string())),

    endereco: ss.nullable(ss.optional(Endereco)),
    contato: ss.nullable(ss.optional(Contato)),
    arquivoLicenseId: ss.nullable(ss.optional(ss.any())),
  })
)
export type IdentidadePF = ss.Infer<typeof IdentidadePF>

// --------------------------------------------------------------------------------------

export const Signatario = ss.assign(
  DBRow,
  ss.object({
    contratoId: ss.optional(ss.nullable(ss.string())),
    assinaturaId: ss.optional(ss.nullable(ss.string())),
    integradorId: ss.optional(ss.nullable(ss.string())),
    distribuidorId: ss.optional(ss.nullable(ss.string())),
    vendedorId: ss.optional(ss.nullable(ss.string())),
    clienteId: ss.optional(ss.nullable(ss.string())),
    assinado: ss.optional(ss.nullable(ss.boolean())),
    generatedContractUrl: ss.optional(ss.nullable(ss.string())),

    integrador: ss.optional(ss.nullable(ss.any())),
    distribuidor: ss.optional(ss.nullable(ss.any())),
    vendedor: ss.optional(ss.nullable(ss.any())),
    cliente: ss.optional(ss.nullable(ss.any())),

    contrato: ss.optional(
      ss.nullable(
        ss.assign(
          DBRow,
          ss.object({
            nomeDocumento: ss.optional(ss.nullable(ss.string())),
            documentoId: ss.optional(ss.nullable(ss.string())),
            inativo: ss.optional(ss.nullable(ss.boolean())),
            type: ss.optional(ss.nullable(ss.string())),
            url: ss.optional(ss.nullable(ss.string())),
          })
        )
      )
    ),
  })
)
export type Signatario = ss.Infer<typeof Signatario>

// --------------------------------------------------------------------------------------

export const Distribuidor = ss.assign(
  DBRow,
  ss.object({
    numeroIntegradoresCadastrados: ss.optional(ss.nullable(ss.string())),
    empresaId: ss.nullable(ss.optional(ss.string())),
    empresa: ss.nullable(ss.optional(Empresa)),
    status: ss.enums(statusUsuario),
    apiToken: ss.optional(ss.nullable(ss.string())),
    convites: ss.optional(ss.nullable(ss.array(ss.any()))),
    equipamentos: ss.optional(ss.nullable(ss.array(Equipamento))),
    integradores: ss.optional(ss.nullable(ss.array(ss.any()))),
    vendedores: ss.optional(ss.nullable(ss.array(ss.any()))),
    lead: ss.optional(ss.nullable(ss.any())),
    value: ss.optional(ss.nullable(ss.any())),

    urlDashboard: ss.optional(ss.nullable(ss.string())),
    scoreQualificacao: ss.optional(ss.nullable(ss.number())),
    ultimoScoreQualificacao: ss.optional(ss.nullable(ss.string())),
    ultimaPropostaCriada: ss.optional(ss.nullable(ss.string())),
    scoreProducao: ss.optional(ss.nullable(ss.number())),
    ultimoScoreProducao: ss.optional(ss.nullable(ss.string())),
    propostas: ss.nullable(ss.optional(ss.array(ss.any()))),
    credenciais: ss.nullable(ss.optional(ss.array(ss.any()))),
    simulacoes: ss.nullable(ss.optional(ss.array(ss.any()))),
    assinaturas: ss.optional(ss.nullable(ss.array(Signatario))),

    temProposta: ss.optional(ss.nullable(ss.boolean())),

    hubSpotId: ss.nullable(ss.optional(ss.string())),

    resultadoP2: ss.nullable(ss.optional(ss.enums(resultadosAnaliseCredito))),
    ultimoResultadoP2: ss.nullable(ss.optional(ss.string())),

    qtdPropostasOriginadas: ss.optional(ss.number()),
    qtdPropostasParticipadas: ss.optional(ss.number()),
    percentualPropostasOriginadasAprovadas: ss.optional(ss.number()),
    percentualPropostasParticipadasAprovadas: ss.optional(ss.number()),
    valorPropostasOriginadas: ss.optional(ss.number()),
    valorPropostasParticipadas: ss.optional(ss.number()),

    webhookToken: ss.optional(ss.nullable(ss.string())),
    webhookUrl: ss.optional(ss.nullable(ss.string())),

    volumeProjetosMes: ss.optional(ss.nullable(ss.string())),
    valorMedioProjetos: ss.optional(ss.nullable(ss.string())),
    porcentagemProjetosFinanciados: ss.optional(ss.nullable(ss.string())),
    servicos: ss.nullable(ss.optional(ss.array(ss.any()))),

    isDuplicate: ss.optional(ss.boolean()),
    isDuplicateOf: ss.optional(ss.string()),
    qtyProjectsCreated: ss.optional(ss.nullable(ss.number())),
    qtyProjectsInstalled: ss.optional(ss.nullable(ss.number())),
    referralId: ss.optional(ss.nullable(ss.string())),
    referral: ss.optional(ss.nullable(ss.any())),
  })
)
export type Distribuidor = ss.Infer<typeof Distribuidor>

// --------------------------------------------------------------------------------------

export const DadosBancarios = ss.deprecated(
  ss.assign(
    DBRow,
    ss.object({
      // integrador: ss.nullable(Integrador),
      integradorId: ss.optional(ss.string()),
      banco: ss.string(),
      tipoConta: ss.enums(tipoContaBanco),
      agencia: ss.string(),
      conta: ss.string(),
      digito: ss.nullable(ss.string()),
      tipoChavePix: ss.enums(tiposChavePix),
      tipoPessoa: ss.optional(ss.enums(tipoPessoa)),
      cnpj: ss.optional(CNPJ),
      cpf: ss.optional(CPF),
      chavePix: ss.string(),
    }),
    ss.object({
      createdAt: ss.optional(ss.string()),
      updatedAt: ss.optional(ss.string()),
    })
  ),
  () =>
    `'DadosBancarios' is deprecated. Please use 'ContaBancaria' and/or 'ChavePix'`
)

export type DadosBancarios = ss.Infer<typeof DadosBancarios>

// --------------------------------------------------------------------------------------

export const ContaBancaria = ss.assign(
  DBRow,
  ss.object({
    integradorId: ss.optional(ss.string()),
    banco: ss.nullable(ss.string()),
    tipoConta: ss.enums(tipoContaBanco),
    agencia: ss.nullable(ss.string()),
    conta: ss.string(),
    principal: ss.boolean(),
    digito: ss.optional(ss.nullable(ss.string())),
    routingNumber: ss.optional(ss.nullable(ss.string())),
    wireRoutingNumber: ss.optional(ss.nullable(ss.string())),
    accountLast4: ss.optional(ss.nullable(ss.string())),
    holderType: ss.optional(ss.nullable(ss.string())),
    holderName: ss.optional(ss.nullable(ss.string())),
    deletedAt: ss.optional(ss.nullable(ss.string())),
    passportAccountId: ss.optional(ss.nullable(ss.number())),
    passportExAccountId: ss.optional(ss.nullable(ss.number())),
    eosWalletId: ss.optional(ss.nullable(ss.string())),
  })
)
export type ContaBancaria = ss.Infer<typeof ContaBancaria>

// --------------------------------------------------------------------------------------

export const ChavePix = ss.assign(
  DBRow,
  ss.object({
    integradorId: ss.optional(ss.string()),
    principal: ss.boolean(),
    chavePix: ss.string(),
    tipoChavePix: ss.enums(tiposChavePix),
    tipoPessoa: ss.optional(ss.enums(tipoPessoa)),
  })
)

export type ChavePix = ss.Infer<typeof ChavePix>

// --------------------------------------------------------------------------------------

export const Convite = ss.object({
  id: ss.optional(ss.nullable(UUID)),
  email: ss.nullable(ss.optional(ss.string())),
  token: ss.nullable(ss.optional(ss.string())),
  nome: ss.nullable(ss.optional(ss.string())),
  tipoDestinatario: ss.nullable(ss.optional(ss.string())),
  tipoRemetente: ss.nullable(ss.optional(ss.string())),
  nomeDestinatario: ss.nullable(ss.optional(ss.string())),
})

export type Convite = ss.Infer<typeof Convite>

// --------------------------------------------------------------------------------------

export const Integrador = ss.assign(
  DBRow,
  ss.object({
    credencialId: ss.optional(ss.nullable(UUID)),
    empresaId: ss.optional(ss.nullable(UUID)),
    identidadePfId: ss.optional(ss.nullable(UUID)),
    vendedorDistribuidorId: ss.optional(ss.nullable(UUID)),
    status: ss.enums(statusUsuario),
    apiToken: ss.optional(ss.nullable(ss.string())),
    responsavelTecnico: ss.nullable(ss.string()),
    cpfResponsavelTecnico: ss.nullable(ss.string()),
    rg: ss.optional(ss.nullable(ss.string())),
    crea: ss.nullable(ss.string()),

    arquivoDocumentoId: ss.optional(ss.nullable(ss.string())),
    arquivoEnergiaId: ss.optional(ss.nullable(ss.string())),

    arquivosProjetos: ss.nullable(
      ss.optional(
        ss.array(ss.optional(ss.nullable(ss.union([FileRecord, ss.string()]))))
      )
    ),
    arquivosEnergiaProjetos: ss.nullable(
      ss.optional(
        ss.array(ss.optional(ss.nullable(ss.union([FileRecord, ss.string()]))))
      )
    ),
    empresa: ss.optional(ss.nullable(Empresa)),
    identidade: ss.optional(ss.nullable(IdentidadePF)),
    credencial: ss.optional(ss.nullable(ss.partial(Credencial))),
    instalacoes: ss.optional(ss.nullable(ss.array(ss.any()))),
    vendedores: ss.optional(ss.nullable(ss.array(ss.unknown()))),
    dadosBancarios: ss.deprecated(
      ss.optional(ss.nullable(ss.array(DadosBancarios))),
      (v) =>
        console.warn(
          `'dadosbancarios' is deprecated, but value was ${v}. Please use 'contasBancarias' and 'chavesPix'`
        )
    ),
    contasBancarias: ss.optional(ss.nullable(ss.array(ContaBancaria))),
    chavesPix: ss.optional(ss.nullable(ss.array(ChavePix))),
    ConcessionariaAcessos: ss.optional(
      ss.nullable(ss.array(AcessoConcessionaria))
    ),
    equipamentos: ss.optional(ss.nullable(ss.array(Equipamento))),
    distribuidores: ss.optional(ss.nullable(ss.array(Distribuidor))),
    convites: ss.optional(ss.nullable(ss.array(ss.any()))),
    urlDashboard: ss.optional(ss.nullable(ss.string())),
    vendedorId: ss.nullable(ss.optional(ss.string())),

    scoreQualificacao: ss.optional(ss.nullable(ss.number())),
    ultimoScoreQualificacao: ss.optional(ss.nullable(ss.string())),
    ultimaPropostaCriada: ss.optional(ss.nullable(ss.string())),
    scoreProducao: ss.optional(ss.nullable(ss.number())),
    ultimoScoreProducao: ss.optional(ss.nullable(ss.string())),

    propostas: ss.nullable(ss.optional(ss.any())),
    simulacoes: ss.nullable(ss.optional(ss.array(ss.any()))),
    assinaturas: ss.optional(ss.nullable(ss.array(Signatario))),
    score: ss.optional(ss.nullable(ss.number())),

    temProposta: ss.optional(ss.nullable(ss.boolean())),

    justificativas: ss.nullable(ss.optional(ss.array(ss.any()))),

    hubSpotId: ss.nullable(ss.optional(ss.string())),
    resultadoP2: ss.nullable(ss.optional(ss.enums(resultadosAnaliseCredito))),
    ultimoResultadoP2: ss.nullable(ss.optional(ss.string())),

    qtdPropostasOriginadas: ss.optional(ss.number()),
    qtdPropostasParticipadas: ss.optional(ss.number()),
    percentualPropostasOriginadasAprovadas: ss.optional(ss.number()),
    percentualPropostasParticipadasAprovadas: ss.optional(ss.number()),
    valorPropostasOriginadas: ss.optional(ss.number()),
    valorPropostasParticipadas: ss.optional(ss.number()),

    webhookToken: ss.optional(ss.nullable(ss.string())),
    webhookUrl: ss.optional(ss.nullable(ss.string())),

    volumeProjetosMes: ss.optional(ss.nullable(ss.string())),
    valorMedioProjetos: ss.optional(ss.nullable(ss.string())),
    porcentagemProjetosFinanciados: ss.optional(ss.nullable(ss.string())),
    servicos: ss.nullable(ss.optional(ss.array(ss.any()))),
    passportId: ss.optional(ss.nullable(ss.number())),

    isDuplicate: ss.optional(ss.boolean()),
    isDuplicateOf: ss.optional(ss.string()),
    pipedriveId: ss.optional(ss.nullable(ss.string())),
    qtyProjectsCreated: ss.optional(ss.nullable(ss.number())),
    qtyProjectsInstalled: ss.optional(ss.nullable(ss.number())),
    referralId: ss.optional(ss.nullable(ss.string())),
    referral: ss.optional(ss.nullable(ss.any())),
  })
)
export type Integrador = ss.Infer<typeof Integrador>

// --------------------------------------------------------------------------------------

export const Backoffice = ss.assign(
  DBRow,
  ss.object({
    nomeCompleto: ss.optional(ss.nullable(ss.string())),
    credencialId: ss.optional(ss.string()),
    identidadePfId: ss.optional(ss.nullable(ss.string())),
    identidade: ss.optional(ss.nullable(IdentidadePF)),
    urlDashboard: ss.optional(ss.nullable(ss.string())),
    simulacoes: ss.nullable(ss.optional(ss.array(ss.any()))),
    isAdmin: ss.optional(ss.nullable(ss.boolean())),
    apiToken: ss.optional(ss.nullable(ss.string())),
    credencial: ss.optional(ss.nullable(Credencial)),
    agenteNeppo: ss.optional(ss.nullable(ss.string())),
  })
)
export type Backoffice = ss.Infer<typeof Backoffice>

// --------------------------------------------------------------------------------------

export const Vendedor = ss.assign(
  DBRow,
  ss.object({
    credencialId: ss.optional(ss.nullable(UUID)),
    identidadePfId: ss.optional(ss.nullable(UUID)),
    integradorId: ss.optional(ss.nullable(UUID)),
    distribuidorId: ss.optional(ss.nullable(UUID)),
    credencial: ss.optional(ss.nullable(ss.partial(Credencial))),
    identidade: ss.optional(ss.nullable(IdentidadePF)),
    integradores: ss.optional(ss.nullable(ss.array(Integrador))),
    distribuidor: ss.optional(ss.nullable(Distribuidor)),
    integrador: ss.optional(ss.nullable(Integrador)),
    valorTotalVendas: ss.optional(ss.number()),
    totalPropostas: ss.optional(ss.number()),
    totalPropostasAprovadas: ss.optional(ss.number()),
    percentualPropostasAprovadas: ss.optional(ss.nullable(ss.number())),
    dataUltimoEnvio: ss.optional(ss.string()),
    urlDashboard: ss.optional(ss.nullable(ss.string())),
    propostas: ss.nullable(ss.optional(ss.array(ss.unknown()))),
    simulacoes: ss.nullable(ss.optional(ss.array(ss.any()))),
    assinaturas: ss.optional(ss.nullable(ss.array(Signatario))),
    inviteId: ss.optional(ss.nullable(ss.string())),
    invite: ss.optional(
      ss.nullable(
        ss.object({
          aceito: ss.boolean(),
        })
      )
    ),

    hubSpotId: ss.nullable(ss.optional(ss.string())),
    pipedriveId: ss.nullable(ss.optional(ss.string())),

    qtdPropostasOriginadas: ss.optional(ss.number()),
    qtdPropostasParticipadas: ss.optional(ss.number()),
    percentualPropostasOriginadasAprovadas: ss.optional(ss.number()),
    percentualPropostasParticipadasAprovadas: ss.optional(ss.number()),
    valorPropostasOriginadas: ss.optional(ss.number()),
    valorPropostasParticipadas: ss.optional(ss.number()),
    referralId: ss.optional(ss.nullable(ss.string())),
    referral: ss.optional(ss.nullable(ss.any())),
  })
)

export type Vendedor = ss.Infer<typeof Vendedor>

// --------------------------------------------------------------------------------------

export const Cliente = ss.assign(
  DBRow,
  ss.object({
    credencialId: ss.optional(ss.string()),
    identidadePfId: ss.nullable(ss.optional(ss.string())),
    credencial: ss.optional(ss.nullable(Credencial)),
    identidade: ss.optional(ss.nullable(IdentidadePF)),
    empresaId: ss.nullable(ss.optional(ss.string())),
    rendaMensal: ss.nullable(ss.optional(ss.number())),
    empresa: ss.nullable(ss.optional(Empresa)),
    propostas: ss.nullable(ss.optional(ss.array(ss.unknown()))),
    logs: ss.optional(ss.array(ss.any())),

    arquivoIrpfId: ss.nullable(ss.optional(ss.string())),
    arquivoHoleriteId: ss.nullable(ss.optional(ss.string())),
    arquivoExtratoBancarioId: ss.nullable(ss.optional(ss.string())),

    arquivoIrpf: ss.nullable(ss.optional(FileRecord)),
    arquivoHolerite: ss.nullable(ss.optional(FileRecord)),
    arquivoExtratoBancario: ss.nullable(ss.optional(FileRecord)),
    simulacoes: ss.nullable(ss.optional(ss.array(ss.any()))),
    assinaturas: ss.optional(ss.nullable(ss.array(Signatario))),

    cargoFuncao: ss.optional(ss.nullable(ss.string())),

    nomeMae: ss.optional(ss.nullable(ss.string())),
    sexo: ss.optional(ss.nullable(ss.string())),
    estadoCivil: ss.optional(ss.nullable(ss.string())),
    nacionalidade: ss.optional(ss.nullable(ss.string())),
    patrimonio: ss.optional(ss.nullable(ss.number())),
    situacaoImovel: ss.optional(ss.nullable(ss.string())),
    naturezaOcupacao: ss.optional(ss.nullable(ss.string())),
    tempoEmpresa: ss.optional(ss.nullable(ss.string())),
    referenciaPessoalNome: ss.optional(ss.nullable(ss.string())),
    referenciaPessoalTelefone: ss.optional(ss.nullable(ss.string())),
    outrasRendas: ss.optional(ss.nullable(ss.number())),
    outrasRendasDescricao: ss.optional(ss.nullable(ss.string())),

    nomeEmpresa: ss.optional(ss.nullable(ss.string())),
    enderecoComercial: ss.optional(ss.nullable(Endereco)),
    enderecoResidencial: ss.optional(ss.nullable(Endereco)),
    enderecoComercialId: ss.optional(ss.nullable(ss.string())),
    enderecoResidencialId: ss.optional(ss.nullable(ss.string())),
    dataNascimento: ss.optional(ss.nullable(ss.string())),

    resultadoP1: ss.nullable(ss.optional(ss.enums(resultadosAnaliseCredito))),
    resultadoP2: ss.nullable(ss.optional(ss.enums(resultadosAnaliseCredito))),
    ultimoResultadoP1: ss.nullable(ss.optional(ss.string())),
    ultimoResultadoP2: ss.nullable(ss.optional(ss.string())),
  })
)

export type Cliente = ss.Infer<typeof Cliente>

export const PropostaCliente = ss.assign(
  DBRow,
  ss.object({
    id: ss.nullable(ss.optional(ss.string())),
    propostaId: ss.nullable(ss.optional(ss.string())),
    clienteId: ss.nullable(ss.optional(ss.string())),
    nome: ss.nullable(ss.optional(ss.string())),
    sobrenome: ss.nullable(ss.optional(ss.string())),
    nomeCompleto: ss.nullable(ss.optional(ss.string())),
    nomeFantasia: ss.nullable(ss.optional(ss.string())),
    cpf: ss.nullable(ss.optional(ss.string())),
    cnpj: ss.nullable(ss.optional(ss.string())),
    telefone: ss.nullable(ss.optional(ss.string())),
    tipoTelefone: ss.nullable(ss.optional(ss.string())),
    dataNascimento: ss.nullable(ss.optional(ss.string())),
    rg: ss.nullable(ss.optional(ss.string())),
    email: ss.nullable(ss.optional(ss.string())),
    logradouro: ss.nullable(ss.optional(ss.string())),
    cidade: ss.nullable(ss.optional(ss.string())),
    estado: ss.nullable(ss.optional(ss.string())),
    cep: ss.nullable(ss.optional(ss.string())),
    complemento: ss.nullable(ss.optional(ss.string())),
    googleAddressDescription: ss.optional(ss.nullable(ss.string())),
    googleAddressId: ss.optional(ss.nullable(ss.string())),
    situacaoProfissional: ss.nullable(ss.optional(ss.string())),
    situacaoResidencial: ss.nullable(ss.optional(ss.string())),
    rendaAnual: ss.nullable(ss.optional(ss.number())),
    ocupacao: ss.nullable(ss.optional(ss.string())),
    nomeEmpresa: ss.nullable(ss.optional(ss.string())),
    tempoEmpresaMeses: ss.nullable(ss.optional(ss.number())),
    primaryIncomeSource: ss.nullable(ss.optional(ss.string())),
    monthlyIncome: ss.nullable(ss.optional(ss.string())),
    otherMonthlyIncome: ss.nullable(ss.optional(ss.string())),
    otherMonthlyIncomeDesc: ss.nullable(ss.optional(ss.string())),
  })
)

export type PropostaCliente = ss.Infer<typeof Cliente>

// --------------------------------------------------------------------------------------

export const User = ss.union([
  Integrador,
  Distribuidor,
  Backoffice,
  Vendedor,
  Cliente,
  Funding,
])
export type User = ss.Infer<typeof User>

// --------------------------------------------------------------------------------------

export type Populate<T, K extends keyof T> = Omit<T, K> & {
  [P in K]: Exclude<T[P], null>
}

// --------------------------------------------------------------------------------------

export const statusCredencial = ['ATIVA', 'INATIVA'] as const
export type StatusCredencial = (typeof statusCredencial)[number]

export const statusInvite = ['ACCEPTED', 'PENDING'] as const
export type StatusInvite = (typeof statusInvite)[number]

export const statusCredencialDisplay = (s: StatusCredencial, t: Translate) =>
  ({
    ATIVA: t('common.credential-active'),
    INATIVA: t('common.credential-inactive'),
  }[s])

export const statusInviteDisplayData = (s: StatusInvite, t: Translate) =>
  ({
    ACCEPTED: t('common.invite-status-accepted'),
    PENDING: t('common.invite-status-pending'),
  }[s])

export const statusCredencialColor = (s: StatusCredencial) => {
  switch (s) {
    case 'ATIVA':
      return 'text-success-500 border-success-300 bg-success-100'
    case 'INATIVA':
      return 'text-danger-500 border-danger-200 bg-danger-100'
  }
}

export const statusInviteColor = (s: StatusInvite) => {
  switch (s) {
    case 'ACCEPTED':
      return 'text-success-500 border-success-300 bg-success-100'
    case 'PENDING':
      return 'text-danger-500 border-danger-200 bg-danger-100'
  }
}

export const statusDistribuidorColor = (s: StatusCredencial | 'LEAD') => {
  return s !== 'LEAD'
    ? statusCredencialColor(s)
    : 'text-warning-400 border-warning-200 bg-warning-100'
}

export const statusDistribuidorDisplay = (
  s: StatusCredencial | 'LEAD',
  t: Translate
) => (s !== 'LEAD' ? statusCredencialDisplay(s, t) : 'Lead')

export const statusInviteDisplay = (s: StatusInvite, t: Translate) =>
  statusInviteDisplayData(s, t)

export const statusCredencialOptions = (
  t: Translate
): Option<StatusCredencial | 'LEAD'>[] => [
  ...statusCredencial.map((s) => ({
    label: statusCredencialDisplay(s, t),
    value: s,
  })),
]

export const mapInvativaStatus = (b?: boolean | null) =>
  ({ true: statusCredencial[1], false: statusCredencial[0] }[String(b)])
